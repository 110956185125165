import React from 'react'

import HeaderMobile from './HeaderMobile'
import Logo from './Logo'

const Header = () => (
  <header id='header'>
    <div className='not-mobile'>
      <h1 className='logo'>
        <span className='logo-content' tabIndex='-1'>
          <span style={{ fontSize: "32px" }}>
            <strong style={{ color: '#3F48CC' }}>RNP</strong> initializr
          </span>
        </span>
      </h1>
    </div>
    <HeaderMobile />
  </header>
)

export default Header
